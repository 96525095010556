import Tab from "bootstrap/js/dist/tab";

// Toggle for Course Schedule and Course Curriculum
let btn_course_display = document.querySelectorAll('.btn-course-display');

btn_course_display.forEach(( button )=>{
  button.addEventListener( 'click', event => {
    event.preventDefault();

    let course_display_id = button.getAttribute('href');
    course_display_id = course_display_id.replace('#','');

    let course_display = document.getElementById(course_display_id);
    let parent_node = button.parentNode;
    parent_node.classList.toggle('show');
    course_display.classList.toggle('show');
  });
});

/* Begin All Tab Functionality */

let programTabsWrap = document.getElementById('program-tabs-wrap');
let programTabs = document.getElementById('program-tabs');


// Get URL hash and show a tab based on hash
let url = window.location.href.replace(/\/$/, "");

if ( window.location.hash ) { // Might need to check for anchors??
  const hash = url.split('#');
  let tab_parent = document.getElementById('program-tabs'),
      tab = document.querySelector('#program-tabs a[href="#'+hash[1]+'"]');

  if( tab ){
    Tab.getOrCreateInstance(tab).show();

    url = window.location.href
    window.history.replaceState(null, null, url);
    setTimeout(() => {
      tab_parent.scrollIntoView({behavior: "smooth"});
      scrollTabNav( programTabsWrap,programTabs ,tab );
    }, 400);
  }

}

let tab_links = document.querySelectorAll('#program-tabs a');

// Change URL hash on each tab click and scroll the tab list
tab_links.forEach(( link )=>{
  link.addEventListener('click', ()=>{

    const hash = link.getAttribute('href');
    let newUrl;

    newUrl = url.split("#")[0] + hash;

    window.history.replaceState(null, null, newUrl);

    scrollTabNav( programTabsWrap,programTabs ,link );

  });
});

// Scroll the tab list
const scrollTabNav = ( tabsWrap,tabs,tabLeft )=>{

  let linkLeft = tabLeft.offsetLeft;

  let scrollPosition = ( tabs.scrollWidth-tabs.clientWidth ) - ( (tabs.scrollWidth-linkLeft) - tabs.clientWidth ) ;

  tabsWrap.scrollTo({
    top: 0,
    left: scrollPosition,
    behavior: 'smooth'
  });

}

export { scrollTabNav };
